.dashborad-info__container{
  width: 100%;
  max-width: 594px;
}

.flex{
  display: flex;
}

.barras {
  height: 15px;
  border-radius: 7.5px;
}
.barra-fondo {
  width: 100%;
  max-width: 594px;
  background: #d9d9d9;
}

.barra-value {
  height: 15px;
  background: #002057;
}

.statistics-container{
  justify-content: space-between;
  align-items: center;
}

.statistics-container p{
  margin: 0;
}

