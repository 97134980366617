.share-button{
    background-color: #E2231A ;
    border: none;
    border-radius: 8px;
    color: white;
    width: 150px;
    padding: 10px 0px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
}

.share-button:hover{
    background-color: #ff0f07;
    box-shadow: none;
}

.share-button:active{
    transform: translateY(1px);
}

