.main {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  border-radius: 15px;
  background-color: white;
  margin: 20px 0;
}

.row1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.row2 {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.row3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
