.edit-theme {
  display: flex;
  justify-content: flex-end;
}

.edit-theme__container {
  position: relative;
  top: -20px;
  right: 1em;
  background-color: white;
  z-index: 21;
  display: flex;
  justify-content: center;
  gap: 0.3em;
  padding: 0.5em 1em;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.377) 0px 7px 29px 0px;
  user-select: none;
}
.edit-theme__container:active {
  background-color: #e9e9e9;
  transform: translateY(1px);
}
.edit-theme__button {
  font-size: 0.8rem;
  font-weight: 600;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.377) 0px 7px 29px 0px;
  padding: 1em;
  background-color: white;
  user-select: none;
}
.card:active {
  transform: translateY(-1px);
}
.card-title {
  font-weight: 800;
  color: #002057;
  font-size: 16px;
  line-height: 22px;
}
.card-text{
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  position: relative;
  bottom: 10px;
}

.card-container__themes {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.card-container__themes__button {
  width: 4em;
  height: 2em;
}

.card-image {
  padding: .5em;
  background-image: url("../../../assets/images/checker_bg.png");
  width: 100%;
  height: 100px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-image img{
  max-height: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  
  .edit-theme__button{
    display: none;
  }
}
  

