a.page-link {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: rgb(0, 32, 87);
}

a.page-link:hover {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: rgb(0, 32, 87);
  transition: all 0.5s;
}

.custom-nav-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-top: 3px;
  margin-left: -1px;
  line-height: 1.25;
  background-color: transparent;
  border: none;
}

.custom-nav-link a {
  color: #da291c;
  font-weight: 700;
}
