.card-container {
  width: 186px;
  height: 233px;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5em;
}

.card-container .card-image__url{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
}

.card-container .card-image__url img{
  height: 100%;
}