.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 500px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
  padding: 10px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
