.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.361);
}

.modal-social__container .modal-content {
  border-radius: 8px;
  padding: 2em;
}

.modal-social__head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.modal-social__head h2 {
  font-size: 30px;
}
.modal-social__head svg {
  position: absolute;
  right: -1em;
  top: -1em;
  cursor: pointer;
  transform: scale(0.8);
}

.social-buttons__container {
  display: flex;
  justify-content: center;
  gap: 1em;
  align-items: center;
  margin-bottom: 4em;
}
.social-buttons__container button {
  border: none;
  background-color: transparent;
  box-shadow: 0px 10px 30px #8a959e3f;
  width: 112px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}
.social-buttons__container button:hover {
  transform: scale(1.05);
}
.social-buttons__container button .bg-grey {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkedin-white {
  border: 1px solid #2867bc;
  border-radius: 50%;
}
.social-buttons__container button .bg-grey svg path {
  fill: #53565a;
}
.social-buttons__container button span {
  width: 70%;
  height: 50px;
  color: #53565a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.social-link__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.7em;
}

.social-link__container input {
  width: 80%;
  height: 40px;
  border: 1px solid #919191;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  font-weight: 600;
  color: #53565a;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
}

.social-link__container button {
  width: 20%;
  height: 40px;
  border: none;
  background-color: #ec0000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.social-link__container button:hover {
  background-color: #ff0000;
}
.social-link__container button:active {
  transform: translateY(1px);
}

@media screen and (max-width: 992px) {
  .modal-social__container .modal-content {
    padding: 1em;
  }
  .modal-social__head {
    padding: 0em;
  }
  .modal-social__head svg {
    right: -0.5em;
    top: -0.5em;
  }
  .social-buttons__container {
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    padding: 1em;
    margin-bottom: 2em;
  }
  .social-buttons__container button {
    height: 120px;
  }

  .social-buttons__container button .bg-grey {
    width: 40px;
    height: 40px;
  }
  .social-buttons__container button span {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }
  .social-link__container {
    flex-direction: column;
    gap: 1em;
  }
  .social-link__container input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
  }
  .social-link__container button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
  }
}
