.collections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5em;
}

.collections-name {
  width: 954px;
  display: flex;
  justify-content: flex-start;
}

.cards-collection__container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cards-collection {
  display: flex;
  gap: 3em;
  width: 100%;
  width: 954px;
  padding: 25px 1em;
  position: relative;
  overflow-x: auto;
}

.cards-collection .card-collection-item {
  width: 190px;
  height: 240px;
  box-shadow: 0px 10px 30px 5px rgb(138 149 158 / 30%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-collection-item .card-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-collection-item .card-name p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 170px;
}
