.swal2-actions{
    flex-direction: row-reverse;
    gap: 2em;
}

.swal2-actions button{
    font-weight: bold;
    
}

.swal2-styled.swal2-cancel{
    color:rgb(0, 32, 87) !important; 
    border: 1px solid rgb(0, 32, 87) !important;
    border-radius: 8px !important;
}

.swal2-styled.swal2-cancel:hover{
    background-color: rgb(0, 32, 87) !important;
    color: white !important;
}

.swal2-styled.swal2-confirm{
    color: white !important;
    border-radius: 8px !important;
}

.swal2-styled.swal2-confirm:hover{
    background-color: rgb(0, 32, 87) !important;
    color: white !important;
}