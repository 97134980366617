.btnCloseContainer {
  position: absolute;
  top: 20px;
  right: 30px;
}

.root {
  position: relative;
  padding: 20px;
}

.image {
  display: block;
  margin: 10px auto;
  width: 160px;
  height: auto;
}

.btnWhite {
  border: 2px solid #0a66c2;
  border-radius: 8px;
  color: #0a66c2;
  margin: 12px;
  text-align: center;
}

.btnBlue {
  border: 2px solid #0a66c2;
  background-color: #0a66c2;
  border-radius: 8px;
  margin: 12px;
  text-align: center;
}

.btnBlue:hover,
.btnWhite:hover {
  cursor: pointer;
}

.btnWhite:hover {
  background-color: #f2f2f2;
  transition: all 0.3s;
}

.btnBlue:hover {
  background-color: #0077b5;
  border: 2px solid #0077b5;
  transition: all 0.3s;
}

.badgeName {
  font-weight: bold;
}
