.emision__cancel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.emision__cancel-container button {
  border-radius: 8px;
  transition: transform 0.1 ease;
}
.emision__cancel-container button:hover {
  transform: scale(1.02);
}
.emision__cancel-container button:active {
  transform: translateY(2px);
}

.boton-opcion:hover {
  color: #e51e0f;
}
