.inner-title {
  margin: 0;
  padding: 0;
  line-height: 0.5em;
}

.inner-text {
  margin: 0;
  padding: 0;
  line-height: 0.2em;
}
