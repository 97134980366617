.viewText {
  font-size: 12px;
  color: #53565a;
  font-weight: 600;
}

.badgeName {
  font-size: 20px;
}

.rootDiv {
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 22px;
  margin: 12px 0;
}

@media (max-width: 768px) {
  .viewText {
    text-align: center;
  }

  .badgeName {
    text-align: center !important;
  }
}

.badgeImage {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
