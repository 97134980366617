.badge-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.badge-share__container{
    width: 100%;
    background-color: rgba(0, 255, 255, 0);
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 1em ;
}

.badge-share__container span{
    cursor: pointer;
}

.badge-share__container svg:hover{
    filter: saturate(150%);
    transform: scale(1.05);
}