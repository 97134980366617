.boxTitle {
  background: white;
  box-shadow: 0px 10px 30px 5px rgba(153, 162, 169, 0.1);
  border-radius: 8px;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.miniImage {
  margin: 0 10px;
}

.spanText {
  color: #da291c;
  font-family: NotoSansJPBold;
}

.imageBox {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
}

.active {
  height: 22px;
  color: #02e85e;
}

.inactive {
  height: 22px;
  color: #dadada;
}
