.main-nav {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.navbar {
  justify-content: flex-start !important;
  height: 57px;
}

.navbar-nav {
  flex-direction: row !important;
}

.nav-item {
  padding-left: 45px;
  padding-bottom: 0;
}

.nav-item:hover {
  opacity: 1 !important;
  color: grey;
}

.active {
  border-bottom: 3px solid #da291c;
}

.nav-link {
  color: white !important;
  font-weight: bold !important;
}

.nav-link:active {
  color: white;
}

.nav-link:hover {
  color: white !important;
}

li a.dropdown-toggle::after {
  left: 0% !important;
}

.profile-dropdown {
  border-radius: 50px;
  height: 28px;
  position: relative;
  min-width: 240px;
  padding: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

.profile-desktop-image {
  width: 22px;
  height: 22px;
  background-color: grey;
  display: inline-block;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 2;
  left: 2;
}

.divider {
  width: 100%;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
  background-color: grey;
}

.row-item {
  display: flex;
  align-items: center;
}

.item-menu {
  margin: 20px 0;
}

.subitem-menu {
  display: flex;
  align-items: center;
  margin: 15px 0;
  margin-left: 20px;
}

.mobile__main__menu {
  display: flex;
  align-items: center;
  padding: 20px;
}

.img-brand {
  width: auto;
  height: 55px;
  padding: 5px;
}
