.template-badge__container {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.template-badge__icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 2.5em;
  top: 2.5em;
  cursor: pointer;
}
.template-badge__imagen {
  width: 300px;
  height: 300px;
}
.template-badge__imagen img {
  height: 100%;
}

.badge-share__container {
  width: 100%;
  background-color: rgba(0, 255, 255, 0);
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 1em;
}

.badge-share__container span {
  cursor: pointer;
}

.badge-share__container svg:hover {
  filter: saturate(150%);
  transform: scale(1.05);
}

.template-badge__provider span {
  font-weight: bold;
}
.template-badge__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.template-badge__button button {
  max-width: 477px;
}
.template-badge__collections{
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.collection-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3em;
}
.check_list .link {
  cursor: pointer;
}

.boton-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
