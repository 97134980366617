@media (max-width: 767px) {
  .centerxs {
    text-align: center !important;
  }

  .centerFlexXtraSmall {
    justify-content: center !important;
  }
}

@media (min-width: 768px) {
  .socialDiv {
    margin-top: 30px;
  }
}

.separatorDiv {
  width: 100%;
  border: 2px solid #002057;
  margin: 10px 0;
}
