.stackable-badges__container{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}

.stackable-badges{
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
}

.stackable-badges__badge{
    position: absolute;

}

.stackable-badges__badge img{
    width: 100%;
    filter: saturate(0);
}

.master-badge{
    position: relative;
}

.master-badge img{
    height: 100%;
    position: absolute;
}