.boderleft {
  border-left: 2px solid #e5e5e5 !important;
}

.optiondiv {
  cursor: pointer;
}

.activeoption {
  color: #da291c;
}

.photo-profile {
  object-fit: cover;
  width: 300px;
  height: auto;
  display: block;
  margin: 10px auto;
}
