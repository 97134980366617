.separator {
  border-bottom: 2px solid #002057;
  width: 100%;
}

.border-white-left {
  border-left: 2px solid white;
}

.blue-icon-socials {
  color: #002057;
  font-size: 20px;
  margin: 10px;
}
