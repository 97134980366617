.profile-img {
  width: 150px;
  height: 150px;
  margin: 20px auto;
  display: block;
  border-radius: 50%;
}

.label-form {
  font-family: NotoSansJPBold;
  font-weight: bold !important;
  font-size: 14px;
  color: #32325d;
}

.nameContainer {
  background-color: #01235c;
  border-radius: 5px;
}

.name {
  color: white;
  text-align: center;
}

.imgAvatar {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 10px auto;
}

.formControlCustom {
  height: 38px !important;
  background: #ffffff;
  border: 1px solid #002057 !important;
  box-shadow: 0px 4px 4px rgb(138 149 158 / 30%) !important;
  border-radius: 8px;
  resize: none;
  cursor: auto;
}

.cardCustom {
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25) !important;
  border-radius: 8px;
}
