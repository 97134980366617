.issue-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 277px; */
  height: 180px;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 25px;
  padding: 0 1.5em;
}

.issue-card__data {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1em;
}

.issue-card__data p {
  margin: 0;
  line-height: 1.5;
}
