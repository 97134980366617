.template-badge__info {
  max-width: 1440px;
  width: 100%;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1em;
  gap: 1em;
  padding: 1em;

}

.template-badge__item{
  width: 140px;
  padding: 0 .5em;
  display: flex;
  align-items: center;
  gap: .5em;
}
.template-badge__item svg{
  height: 30px;
  width: 30px;
}
.template-badge__item .template-badge__item__text p{
  margin: 0;
}
.container-template {
  max-width: 1440px;
  width: 100%;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 1em 2em ;
  margin-bottom: 2em;
}
.habilidades-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.habilidades-container .habilidad {
  border: 2px solid #002057;
  border-radius: 10px;
  color: #53565A;
  padding: .5em;
}

.check_list{
  width: 100%;
  display: flex;
  align-items: center;
  gap: .5em;
  color: #53565A;
}

.check_list .check_list__icon{
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
}

.check_list svg {
  width: 15px;
  height: 15px;
}

.check_list .link {
  text-decoration: underline;
}

.container-informacion{
  margin-bottom: 1em;
}

.division{
  display: flex;
  width: 100%;
}

.division .requisitos-container {
  width: 50%;
  border-right: 2px solid #EBEBEB;
}

.division .estandar-container{
  width: 50%;
  padding-left: 2em;
}

.boton-container {
  width: 100%;
}

.boton-container button{
  width: 100%;
  max-width: 477px;
}