.rdt_TableRow {
  font-family: NotoSansJP, serif;
  font-size: 13px !important;
  height: 60px;
}

.rdt_TableHeadRow {
  font-family: NotoSansJP, serif;
  font-size: 13px !important;
  height: 60px;
  text-transform: uppercase;
  font-weight: 700;
}
