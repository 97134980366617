
/* estilos de tags e input */


.input__container{
    display: flex;
    width: 100%;
    border: 1px solid #022561;
    border-radius: 8px;
    padding: 5px 8px;
    gap: 5px;
    flex-wrap: wrap;
}
.input__value{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.input__container .input__text{
    border: none;
    outline: none;
    background: none;
    color: #022561;
}

.tag{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fff;
    padding: 4px;
    gap: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.input__tag button{
    height: 23px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
}
.errors{
    color: red;
    font-size: 12px;
}
.tags__container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    max-height: 220px;
    overflow-y: auto;
}

.tags__tag{
    cursor: pointer;
    position: relative;
    padding: 4px 16px;
}
.tags__tag-buttons{
    display: none;
    gap: 2px;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    /* position: absolute;
    right: 0; */
}
.tags__tag:hover .tags__tag-buttons{
    display: flex;
}

.tags__tag .button__tag{
    height: 23px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    transform: scale(0.7);
    transition: transform 0.2s ease;
}
.tags__tag .button__tag:hover{
    transform: scale(0.8);
}

.editar__button svg{
    height: 20px;
    width: 20px;
    fill: #022561;
}

.divisor__line{
    height: 20px;
    width: 1px;
    background-color: grey;
}

.crear__tag{
    border: 1px solid grey;
    color: #022561;
    cursor: pointer;
}

/* estilos de modales */


.modal__container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.modal__container input{
    border: none;
    border-radius: 20px;
    background-color: aliceblue;
    padding: 8px;
}

.modal__colors-container{
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.modal__colors-container .color{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.modal__colors-container .color:hover{
    transform: scale(1.1);
}

.modal__colors-container .color:active{
    border: 2px solid rgb(157, 157, 255);
}

.modal__colors-container .selected{
    outline: 2px solid rgb(79, 126, 215);
}