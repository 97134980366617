.main {
  background: white;
  width: 80%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 15px;
}
