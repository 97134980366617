.input-container,
.button-container {
  width: 100%;
  max-width: 477px;
  padding: 0 0.5em;
}

.button-container button {
  width: 100%;
  color: white;
  background: #da291c;
  box-shadow: 0px 4px 4px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  border: none;
  height: 44px;
  padding: 11px 45px;
  font-weight: bold;
  cursor: pointer;
}


.button-container button:hover {
  background-color: #002057;
  transition: all 0.3s ease;
}