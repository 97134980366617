$primary-color: #282c34;
$secondary-color: #61dafb;

.box {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
  padding: 30px;
  margin: 10px 0;
}

.box:hover {
  box-shadow: 0px 10px 30px 5px rgb(138 149 158 / 30%);
  transition: box-shadow 1s;
}
