.primary {
  color: white;
  background: #da291c;
  box-shadow: 0px 4px 4px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  border: none;
  height: 44px;
  padding: 11px 45px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

.secondary {
  color: #002057;
  background: #ffffff;
  border: 1px solid #002057;
  border-radius: 8px;
  height: 44px;
  box-shadow: 0px 4px 4px rgba(138, 149, 158, 0.3);
  padding: 11px 45px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

.small {
  font-size: 12px;
  color: white;
  border: 1px solid #002057;
  background: #002057;
  border-radius: 15px;
  font-weight: 700;
  padding: 2px 20px;
  font-family: NotoSansJPBold;
  cursor: pointer;
}

.smallSecondary {
  font-size: 12px;
  color: white;
  border: 1px solid #da291c;
  background: #da291c;
  border-radius: 15px;
  font-weight: 700;
  padding: 2px 20px;
  font-family: NotoSansJPBold;
  cursor: pointer;
}

.transparent {
  font-family: NotoSansJPBold;
  font-size: 12px;
  color: white;
  border: 2px solid #fff;
  background: none;
  border-radius: 8px;
  font-weight: bold;
  height: 40px;
  cursor: pointer;
  padding: 5px 20px;
  box-sizing: border-box;
}

.loading {
  margin-right: 10px;
}

.primary:hover {
  background-color: #002057;
  transition: all 0.3s ease;
}

.secondary:hover {
  background-color: #002057;
  color: white;
  transition: all 0.3s ease;
}
