/* Stylesheet for global styles definition*/

$danger-color: '#da291c';

.text-danger {
  color: $danger-color;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-text-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  display: block;
}

.form-control {
  border: 1px solid #53565a !important;
  color: #53565a !important;
}

.form-control,
.form-control:focus {
  border-color: #022561;
}

.form-control.is-invalid {
  border-color: #fb6340 !important;
}

.react-date-picker__wrapper {
  border-radius: 5px;
}

.react-date-picker__calendar {
  z-index: 2;
}

.custom-col-5 {
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  width: 20%;
}

@media (max-width: 768px) {
  .custom-col-5 {
    float: left;
    width: 100%;
    margin: 10px;
  }
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
@media (min-width: 576px) {
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media (min-width: 768px) {
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media (min-width: 992px) {
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media (min-width: 1200px) {
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.custom-box-col1 {
  float: left;
  width: 16%;
  margin: 10px;
  cursor: pointer;
}

.custom-box-col2 {
  float: left;
  width: 18%;
  margin: 10px;
  cursor: pointer;
}

.custom-sm-box-col {
  float: left;
  width: 3%;
}

.center-image {
  display: block;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .custom-box-col1 {
    float: left;
    width: 100%;
    text-align: center;
    margin: 15px;
  }

  .custom-box-col2 {
    float: left;
    width: 100%;
    text-align: center;
    margin: 15px;
  }

  .custom-sm-box-col {
    float: left;
    width: 100%;
  }
}

.skill-box {
  border: 2px solid;
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
}

.gray {
  filter: grayscale(1);
}

.mainContainer {
  width: 200px;
  height: 200px;
  margin: 10px auto;
  position: relative;
}

.imageBox {
  position: absolute;
  top: 0;
  left: 0;
}

.grey {
  filter: grayscale(100%);
  opacity: 0.3;
}

.color {
  height: 100%;
  animation: fillbagde 2s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes fillbagde {
  from {
    height: 0%;
  }

  to {
    height: 45%;
  }
}

.navbar {
  justify-content: flex-start;
}

.navbar-nav {
  flex-direction: row;
  padding-left: 20px;
}

.active {
  border-bottom: none;
}
